<template>
    <div class="creator-card">
        <div class="creator-header">
            <div class="row flex-nowrap text-truncate no-gutters w-100">
                <div class="col text-truncate">
                    <user-picture-name-nickname :user="creator" />
                </div>
                <div class="col-auto">
                    <follow-entity-button
                        :entity-id="creator.id"
                        :entity-name="creator.displayname"
                        :is-following="Number(creator.is_following)"
                        entity="users"
                        @toggled-follow="(is_following) => $emit('toggled-follow', is_following)"
                    />
                </div>
            </div>
        </div>
        <p class="creator-description">
            {{ creator.description }}
        </p>
        <div class="creator-label">
            {{ creator.top_description }}
        </div>
    </div>
</template>

<script>
export default {
    namme: "CreatorCard",
    components: {
        UserPictureNameNickname: () => import(/* webpackChunkName: "user-picture-name-nickname" */ "@c/molecules/user-picture-name-nickname"),
        FollowEntityButton: () => import(/* webpackChunkName: "follow-entity-button" */ "@c/atoms/follow-entity-button")
    },
    props: {
        creator: {
            type: Object,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
.creator-card {
    width: 500px;
    background-color: #1C1C1E;
    padding: 12px;
    margin-bottom: 24px;
    border-radius: 8px;

    @media(max-width: $sm) {
        max-width: 300px;
    }

    .user-picture-name-followers {
        /deep/ .row {
            flex-wrap: nowrap;

            .col.col-sm-auto {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                flex-basis: 0;
                flex-grow: 1;
                max-width: 100%;
            }
        }
    }

    .creator-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;

        /deep/ .user-picture {
            .verified-user {
                width: 20px;
            }
        }
    }

    .creator-description {
        @extend .body-text;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 12px;
    }

    .creator-label {
        @extend .body-text;
        background-color: #2C2C2E;
        padding: 6px;
        border-radius: 4px;
        color: #979797;
        width: fit-content;
    }
}
</style>

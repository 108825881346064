import { render, staticRenderFns } from "./creator-card.vue?vue&type=template&id=5ffd2efe&scoped=true&"
import script from "./creator-card.vue?vue&type=script&lang=js&"
export * from "./creator-card.vue?vue&type=script&lang=js&"
import style0 from "./creator-card.vue?vue&type=style&index=0&id=5ffd2efe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ffd2efe",
  null
  
)

export default component.exports